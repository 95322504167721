

.App-header {
  /* background-color: #282c34; */
  background-color: #14171f;
  min-height: 100vh;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center;
  justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.tool-icon-container img {
  width: 50px;
  border-radius: 12px;
}

.MuiCard-root{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 12px;
}


.wallet-adapter-dropdown .wallet-adapter-button .wallet-adapter-button-start-icon {
	display: none !important;
}

.wallet-adapter-button {
    /* border: 2px solid #90caf9 !important; */
    color: white !important;
	border-radius: 6px !important;
 }

 .wallet-adapter-button-trigger {
	 background: #90caf9 !important;
   color: black !important;
   font-weight: 300 !important;
   text-transform: uppercase !important;
   height: 35px !important;
   padding-right: 10px !important;
   padding-left: 10px !important;
   border-radius: 4px !important;
}